import React from "react";
import "./style.css";
import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { dataportfolio, meta } from "../../content_option";

export const Portfolio = () => {
	const [repos, setRepos] = useState([]);

	const setUpData = async () => {
		const response = await fetch(
			"https://api.github.com/users/effordDev/repos"
		);
		let data = await response.json();
		console.log(data);
		// data = data
		// 	.filter(
		// 		(repo) => repo.stargazers_count > 0 && repo.name.includes("sf-")
		// 	)
		// 	.sort((a, b) => b.stargazers_count - a.stargazers_count);
		setRepos(
			data
				.filter(
					(repo) =>
						repo.stargazers_count > 0 && repo.name.includes("sf-")
				)
				.sort((a, b) => b.stargazers_count - a.stargazers_count)
		);
	};

	useEffect(() => {
		setUpData();
	}, []);

	return (
		<HelmetProvider>
			<Container className="About-header">
				<Helmet>
					<meta charSet="utf-8" />
					<title> Portfolio | {meta.title} </title>{" "}
					<meta name="description" content={meta.description} />
				</Helmet>
				<Row className="mb-5 mt-3 pt-md-3">
					<Col lg="8">
						<h1 className="display-4 mb-4"> Portfolio </h1>{" "}
						<hr className="t_border my-4 ml-0 text-left" />
					</Col>
				</Row>
				{/* <div className="mb-4 po_items_ho"> */}
				{repos.length ? (
					repos.map((item, i) => {
						return (
							<Row
								className="repo_container"
								key={i}
								onClick={() =>
									window.open(item.html_url, "_blank")
								}
							>
								<Col sm>
									<div className="repo_name">
										{item.name}
										<span className="repo_item">
											⭐: {item.stargazers_count}
										</span>
									</div>
									{item.description && (
										<div className="repo_description">
											📝: {item.description}
										</div>
									)}
								</Col>
							</Row>
						);
					})
				) : (
					<Spinner />
				)}
				{/* </div> */}
			</Container>
		</HelmetProvider>
	);
};
