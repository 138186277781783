import React from "react";
import "./style.css";
import {
	FaGithub,
	// FaTwitter,
	// FaFacebookF,
	FaLinkedin,
	FaInstagram,
	FaCoffee,
	// FaYoutube,
	// FaTwitch,
} from "react-icons/fa";
import { socialprofils } from "../../content_option";

export const Socialicons = (params) => {
	return (
		<div className="stick_follow_icon">
			<ul>
				{/* {socialprofils.twitter && (
          <li>
            <a href={socialprofils.twitter}>
              <FaTwitter />
            </a>
          </li>
        )} */}
				{socialprofils.github && (
					<li>
						<a href={socialprofils.github}>
							<FaGithub />
						</a>
					</li>
				)}
				{/* {socialprofils.facebook && (
          <li>
            <a href={socialprofils.facebook}>
              <FaFacebookF />
            </a>
          </li>
        )} */}
				{socialprofils.instagram && (
					<li>
						<a href={socialprofils.instagram}>
							<FaInstagram />
						</a>
					</li>
				)}
				{socialprofils.linkedin && (
					<li>
						<a href={socialprofils.linkedin}>
							<FaLinkedin />
						</a>
					</li>
				)}
				{socialprofils.coffee && (
					<li>
						<a href={socialprofils.coffee}>
							<FaCoffee />
						</a>
					</li>
				)}
				{/* {socialprofils.youtube && (
          <li>
            <a href={socialprofils.youtube}>
              <FaYoutube />
            </a>
          </li>
        )} */}
				{/* {socialprofils.twitch && (
          <li>
            <a href={socialprofils.twitch}>
              <FaTwitch />
            </a>
          </li>
        )} */}
			</ul>
			<p>Follow Me</p>
		</div>
	);
};
