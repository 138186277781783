const logotext = "Reid";
const meta = {
	title: "Reid Efford",
	description:
		"I’m Reid Efford Full stack devloper, currently working in Flagler Beach, Florida",
};

const introdata = {
	title: "Hello, I'm Reid Efford",
	animated: [
		`I develop/specialize in web 
        site design and development, mobile apps, 
        and database architecture`,
		`Technologies I enjoy: 
        Lightning Web Components, 
        React, 
        React Native, 
        Sass,
        Webpack,
        Nodejs,
        Express,
        MongoDB,
        Firebase`,
		`I contribute actively to 
        open-source projects and I'm always 
        currently exploring new technologies`,
	],
	description: "Full Stack Developer based in Flagler Beach, FL. ",
	your_img_url:
		"https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
	title: "abit about my self",
	aboutme: `Reid is an accomplished Full Stack Developer boasting more than 6 years of extensive experience
	in technical consulting. `,
};
const worktimeline = [
	{
		jobtitle: "Senior Salesforce Developer",
		where: "Coastal Cloud",
		date: "2018 - present",
	},
	{
		jobtitle: "Co-Op Web/Systems Developer",
		where: "The Lubrizol Corporation",
		date: "2015",
	},
];

const skills = [
	{
		name: "HTML · Javascript · CSS",
		value: 100,
	},
	{
		name: "NodeJs · ExpressJs",
		value: 100,
	},
	{
		name: "Salesforce · LWC · Apex",
		value: 100,
	},
	{
		name: "React · ReactNative · Svelte · Sass · Handlebars",
		value: 100,
	},
	{
		name: "Redux · Babel · Webpack",
		value: 100,
	},
	{
		name: "MongoDB · Google Web Services · Amazon Web Services",
		value: 100,
	},
];

const services = [
	{
		title: "Salesforce Development",
		description: `Full Stack Senior Salesforce Developer boasting more than 6 years of extensive experience
		in technical consulting. He generates hundreds of thousands of dollars annually in direct margin by providing production-ready, module-based solutions and accelerators. Additionally, 
		he provides fellow developers with reusable, open-source Lightning Web Components to accelerate development. 
		His expertise spans various domains, with a proven track record of architecting solutions
		tailored to the unique needs of clients in the Public Sector, Sales, Field Service (with a focus on Technician
		Routing), Healthcare (specifically in Patient Care), and Staffing.`,
	},
	{
		title: "UI & UX Design",
		description: `As a skilled UI/UX Designer, he brings a blend of creativity and technical expertise to design intuitive 
			and visually appealing user interfaces. With a strong background in user experience principles, he is adept at 
			conducting user research, creating wireframes, and developing prototypes to ensure seamless user interactions. 
			He excels in collaborating with cross-functional teams to deliver responsive and accessible designs that 
			enhance user satisfaction and drive business goals.`,
	},
	{
		title: "Web Development",
		description: `With a wealth of hands-on experience across diverse clouds, integration strategies, and data management
practices, Reid is well-versed in the intricacies of web development. His technical proficiency and ability
to navigate the complexities of the Salesforce ecosystem make him a valuable asset for any organization seeking a
seasoned web developer`,
	},
	{
		title: "Mobile Apps",
		description: `solid foundation in mobile development and a keen ability to deliver high-quality 
			applications. With expertise in React Native and other mobile technologies, he is adept 
			at building cross-platform mobile applications that are both functional and user-friendly. His 
			experience includes designing, developing, and maintaining mobile applications, ensuring 
			they are performant and scalable.`,
	},
];

const dataportfolio = [
	{
		img: "https://picsum.photos/400/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/800/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/600/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/300/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/700/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},

	{
		img: "https://picsum.photos/400/600/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/300/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/550/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
	{
		img: "https://picsum.photos/400/700/?grayscale",
		description:
			"The wisdom of life consists in the elimination of non-essentials.",
		link: "#",
	},
];

const contactConfig = {
	YOUR_EMAIL: "dev@tokyotech.us",
	YOUR_FONE: "+1 440 596 7189",
	description:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula eu nunc et sollicitudin. Cras pulvinar, nisi at imperdiet pharetra. ",
	// creat an emailjs.com account
	// check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
	YOUR_SERVICE_ID: "service_kotviml",
	YOUR_TEMPLATE_ID: "template_roco3om",
	YOUR_USER_ID: "tTDbdC7vz5ExecdYE",
};

const socialprofils = {
	github: "https://github.com/effordDev",
	instagram: "https://www.instagram.com/reid.speed/",
	linkedin: "https://www.linkedin.com/in/reid-efford-359807a7/",
	coffee: "https://buymeacoffee.com/reideffordt",
	// facebook: "https://facebook.com",
	// twitter: "https://twitter.com",
};
export {
	meta,
	dataabout,
	dataportfolio,
	worktimeline,
	skills,
	services,
	introdata,
	contactConfig,
	socialprofils,
	logotext,
};
